<template>
  <div>
    <b-card>
      <!-- Media -->
      <b-media class="mb-2">
        <template #aside>
          <b-avatar
            v-if="user !== null"
            ref="previewEl"
            :src="avatar !== '' ? avatar : user.hasOwnProperty('photo') ? `https://mfsgafrique.com/api-scientist/public${user.photo.contentUrl}` : avatar"
            :text="user.fullName.substring(0,1)"
            :variant="`light-primary`"
            size="90px"
            rounded
          />
        </template>
        <h4 class="mb-1">
          {{ user.fullName }}
        </h4>
        <div class="d-flex flex-wrap">
          <b-button
            variant="primary"
            @click="$refs.refInputEl.click()"
          >
            <input
              ref="refInputEl"
              type="file"
              class="d-none"
              @input="inputImageRenderer"
            >
            <span class="d-none d-sm-inline"> {{ user.hasOwnProperty('photo') ? 'Update picture' : 'Add picture' }} </span>
            <feather-icon
              icon="EditIcon"
              class="d-inline d-sm-none"
            />
          </b-button>
          <b-button
            v-if="avatar !== ''"
            variant="outline-secondary"
            class="ml-1"
            @click="removeImage"
          >
            <span class="d-none d-sm-inline">Delete picture</span>
            <feather-icon
              icon="TrashIcon"
              class="d-inline d-sm-none"
            />
          </b-button>
        </div>
      </b-media>

      <!-- User Info: Input Fields -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- FullName -->
          <validation-provider
            #default="{ errors }"
            name="fullName"
            rules=""
          >
            <b-form-group
              label="First and last name"
              label-for="fullName"
            >
              <b-form-input
                id="fullName"
                v-model="user.fullName"
                :state="errors.length > 0 ? false:null"
                trim
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Titre -->
          <validation-provider
            #default="{ errors }"
            name="title"
            rules=""
          >
            <b-form-group
              label="Qualification"
              label-for="title"
            >
              <v-select
                v-if="other == false"
                v-model="user.title"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="optionsTitre"
                input-id="pays"
                label="title"
                :state="errors.length > 0 ? false:null"
                :reduce="titre => titre.value"
                :clearable="false"
                @input="changeTitleValue"
              />
              <span
                v-else
                class="d-flex justify-content-between"
              >
                <b-form-input
                  id="title"
                  v-model="user.title"
                  :state="errors.length > 0 ? false:null"
                  trim
                />
                <b-button
                  v-if="other === true"
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="gradient-danger"
                  class="ml-2 btn-icon rounded-circle"
                  @click="close"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </span>

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="pays"
            rules="required"
          >
            <b-form-group
              label="Country"
              label-for="pays"
            >
              <v-select
                v-model="user.pays"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="getList"
                input-id="pays"
                label="libelle"
                :state="errors.length > 0 ? false:null"
                :reduce="pays => pays['@id']"
                :clearable="false"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="paysResidence"
            rules="required"
          >
            <b-form-group
              label="Country of residence"
              label-for="paysResidence"
            >
              <v-select
                v-model="user.paysResidence"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="getCountries"
                input-id="pays"
                label="libelle"
                :state="errors.length > 0 ? false:null"
                :reduce="pays => pays.id"
                :clearable="false"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="domaine"
            rules="required"
          >
            <b-form-group
              label="Domain"
              label-for="domaine"
            >
              <b-form-select
                id="domainSelect"
                v-model="user.domaine"
                :options="domains"
                :state="errors.length > 0 ? false:null"
              />
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- phone -->
          <validation-provider
            #default="{ errors }"
            name="phone"
            rules="required"
          >
            <b-form-group
              label="Phone number"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="user.phone"
                type="number"
                :state="errors.length > 0 ? false:null"
                trim
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Adresse -->
          <validation-provider
            #default="{ errors }"
            name="adresse"
            rules="required"
          >
            <b-form-group
              label="Adress"
              label-for="adresse"
            >
              <b-form-textarea
                id="adresse"
                v-model="user.adresse"
                :state="errors.length > 0 ? false:null"
                rows="3"
                trim
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="loading"
            >
              <span v-if="loading === false"> Update </span>
              <span v-else>
                <b-spinner small />
              </span>
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import {
  BButton, BMedia, BAvatar, BSpinner, BFormInput, BFormSelect, BFormTextarea, BFormInvalidFeedback, BFormGroup, BForm, BCard,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    // BRow,
    BFormSelect,
    // BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BFormTextarea,
    // BInputGroupAppend,
    // BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    // BTable,
    BCard,
    // BCardHeader,
    // BCardTitle,
    // BFormCheckbox,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    const domains = [{ value: 'engineering & technology', text: 'Engineering and Technology' }, { value: 'Economics & Econometrics', text: 'Economy and Econometrics' }, { value: 'Medical and Health Sciences', text: 'Medical and Health Science' }, { value: 'Social Sciences', text: 'Social Science' }, { value: 'Natural Sciences', text: 'Natural Science' }]
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const avatar = ref('')
    const loading = ref(false)
    const other = ref(false)
    const optionsTitre = [
      { title: 'Master of Science', value: 'Master of Science' },
      { title: 'PhD', value: 'PhD' },
      { title: 'Professor', value: 'Professor' },
      { title: 'Professor Doctor', value: 'Professor Doctor' },
      { title: 'Emeritus Professor', value: 'Emeritus Professor' },
      { title: 'Others', value: 'Others' },
    ]
    const user = ref({
      fullName: '',
      title: '',
      adresse: '',
      phone: '',
      domaine: '',
      pays: '',
      paysResidence: '',
      photo: '',
      file: '',
    })

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      console.log(refInputEl)
      // eslint-disable-next-line prefer-destructuring
      user.value.file = refInputEl.value.files[0]
      // eslint-disable-next-line no-param-reassign
      avatar.value = base64
    })

    const removeImage = () => {
      avatar.value = ''
      user.value.file = ''
    }

    return {
      avatarText,
      avatar,
      domains,
      required,
      email,
      optionsTitre,
      other,
      removeImage,
      user,
      loading,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },

  computed: {
    getPeople() {
      return this.$store.getters['peoples/list']
    },
    getList() {
      return this.$store.getters['pays/list']
    },
    getCountries() {
      return this.$store.getters['pays/countries']
    },
  },

  async beforeCreate() {
    if (this.$store.getters['peoples/info'] === null) {
      await this.$store.dispatch('peoples/getMe')
      await this.$store.dispatch('peoples/list', { params: { 'user.id': this.$store.getters['peoples/info'].id } })
      // eslint-disable-next-line prefer-destructuring
      this.user = this.getPeople[0]
    } else {
      await this.$store.dispatch('peoples/list', { params: { 'user.id': this.$store.getters['peoples/info'].id } })
      // eslint-disable-next-line prefer-destructuring
      this.user = this.getPeople[0]
    }
    this.user.pays = this.user.pays['@id']
    // eslint-disable-next-line no-prototype-builtins
    this.user.photo = ''
    delete this.user.user
    if (this.$store.getters['pays/list'] === 0) {
      this.$store.dispatch('pays/list', { params: null })
    }
    if (this.$store.getters['pays/countries'] === 0) {
      await this.$store.dispatch('pays/listAll', { params: null })
    }
  },
  methods: {
    changeTitleValue() {
      console.log('Change title value')
      if (this.user.title === 'Others') {
        console.log('Change title value for others')
        this.other = true
        this.user.title = ''
      }
    },
    close() {
      this.other = false
      this.user.title = ''
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          this.loading = true
          await this.$store.dispatch('peoples/update', { id: this.user.id, params: null, item: this.user }).then(async () => {
            this.$router.push('/')
          }).catch(async error => {
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              duration: 8000,
              props: {
                title: `${error.response.data['hydra:description']}`,
                icon: 'ErrorIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
