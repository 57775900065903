var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-media',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"aside",fn:function(){return [(_vm.user !== null)?_c('b-avatar',{ref:"previewEl",attrs:{"src":_vm.avatar !== '' ? _vm.avatar : _vm.user.hasOwnProperty('photo') ? ("https://mfsgafrique.com/api-scientist/public" + (_vm.user.photo.contentUrl)) : _vm.avatar,"text":_vm.user.fullName.substring(0,1),"variant":"light-primary","size":"90px","rounded":""}}):_vm._e()]},proxy:true}])},[_c('h4',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.user.fullName)+" ")]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.refInputEl.click()}}},[_c('input',{ref:"refInputEl",staticClass:"d-none",attrs:{"type":"file"},on:{"input":_vm.inputImageRenderer}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(" "+_vm._s(_vm.user.hasOwnProperty('photo') ? 'Update picture' : 'Add picture')+" ")]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"EditIcon"}})],1),(_vm.avatar !== '')?_c('b-button',{staticClass:"ml-1",attrs:{"variant":"outline-secondary"},on:{"click":_vm.removeImage}},[_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Delete picture")]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"TrashIcon"}})],1):_vm._e()],1)]),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"fullName","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"First and last name","label-for":"fullName"}},[_c('b-form-input',{attrs:{"id":"fullName","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.user.fullName),callback:function ($$v) {_vm.$set(_vm.user, "fullName", $$v)},expression:"user.fullName"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"title","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Qualification","label-for":"title"}},[(_vm.other == false)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.optionsTitre,"input-id":"pays","label":"title","state":errors.length > 0 ? false:null,"reduce":function (titre) { return titre.value; },"clearable":false},on:{"input":_vm.changeTitleValue},model:{value:(_vm.user.title),callback:function ($$v) {_vm.$set(_vm.user, "title", $$v)},expression:"user.title"}}):_c('span',{staticClass:"d-flex justify-content-between"},[_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.user.title),callback:function ($$v) {_vm.$set(_vm.user, "title", $$v)},expression:"user.title"}}),(_vm.other === true)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(40, 199, 111, 0.15)'),expression:"'rgba(40, 199, 111, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-2 btn-icon rounded-circle",attrs:{"variant":"gradient-danger"},on:{"click":_vm.close}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1):_vm._e()],1),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"pays","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Country","label-for":"pays"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.getList,"input-id":"pays","label":"libelle","state":errors.length > 0 ? false:null,"reduce":function (pays) { return pays['@id']; },"clearable":false},model:{value:(_vm.user.pays),callback:function ($$v) {_vm.$set(_vm.user, "pays", $$v)},expression:"user.pays"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"paysResidence","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Country of residence","label-for":"paysResidence"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.getCountries,"input-id":"pays","label":"libelle","state":errors.length > 0 ? false:null,"reduce":function (pays) { return pays.id; },"clearable":false},model:{value:(_vm.user.paysResidence),callback:function ($$v) {_vm.$set(_vm.user, "paysResidence", $$v)},expression:"user.paysResidence"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"domaine","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Domain","label-for":"domaine"}},[_c('b-form-select',{attrs:{"id":"domainSelect","options":_vm.domains,"state":errors.length > 0 ? false:null},model:{value:(_vm.user.domaine),callback:function ($$v) {_vm.$set(_vm.user, "domaine", $$v)},expression:"user.domaine"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Phone number","label-for":"phone"}},[_c('b-form-input',{attrs:{"id":"phone","type":"number","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"adresse","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Adress","label-for":"adresse"}},[_c('b-form-textarea',{attrs:{"id":"adresse","state":errors.length > 0 ? false:null,"rows":"3","trim":""},model:{value:(_vm.user.adresse),callback:function ($$v) {_vm.$set(_vm.user, "adresse", $$v)},expression:"user.adresse"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","disabled":_vm.loading}},[(_vm.loading === false)?_c('span',[_vm._v(" Update ")]):_c('span',[_c('b-spinner',{attrs:{"small":""}})],1)])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }